.footer {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 20px 0;
  flex-shrink: 0;
  border-top: 1px solid var(--border-color);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.copyright {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.made-with-love {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
}

.heart-icon {
  color: var(--error-color);
  margin: 0 5px;
}

.admin-social-links {
  display: flex;
  gap: 20px;
}

.footer-social-link {
  color: var(--text-primary);
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 6px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-social-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--accent-primary);
}

.footer-social-link svg {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .admin-social-links {
    flex-direction: column;
    gap: 10px;
  }

  .footer-social-link {
    justify-content: center;
  }
}
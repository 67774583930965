/* theme.css: 고급 개발자 스타일의 다크 테마 디자인 */

:root {
  /* 기존 색상 변수 */
  --bg-primary: #0d1117;
  --bg-secondary: #161b22;
  --text-primary: #c9d1d9;
  --text-secondary: #8b949e;
  --accent-primary: #58a6ff;
  --accent-secondary: #1f6feb;
  --border-color: #30363d;
  --success-color: #3fb950;
  --error-color: #f85149;
  --warning-color: #d29922;

  /* 폰트 크기 */
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-2xl: 24px;
  --font-size-3xl: 30px;

  /* 곡률 */
  --border-radius-sm: 4px;
  --border-radius-md: 6px;
  --border-radius-lg: 8px;
  --border-radius-xl: 12px;

  /* 간격 */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;

  /* 그림자 */
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);

  /* 트랜지션 */
  --transition-fast: 0.1s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;
}

/* 전체 페이지 기본 스타일 */
body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-family: 'Fira Code', 'Roboto Mono', monospace;
  line-height: 1.6;
  font-size: var(--font-size-md);
}

/* 링크 스타일 */
a {
  color: var(--accent-primary);
  text-decoration: none;
  transition: color var(--transition-normal);
}

a:hover {
  color: var(--accent-secondary);
  text-decoration: underline;
}

/* 네비게이션 스타일 */
nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}

nav ul li {
  margin-left: var(--spacing-md);
}

nav ul li a {
  color: var(--text-secondary);
  font-weight: 500;
  transition: color var(--transition-normal);
}

nav ul li a:hover {
  color: var(--accent-primary);
}

/* 버튼 스타일 */
button {
  background-color: var(--accent-secondary);
  color: var(--text-primary);
  border: none;
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm) var(--spacing-md);
  cursor: pointer;
  transition: background-color var(--transition-normal), transform var(--transition-fast);
  font-family: 'Fira Code', monospace;
  font-size: var(--font-size-sm);
}

button:hover {
  background-color: var(--accent-primary);
  transform: translateY(-2px);
}

button:active {
  transform: translateY(0);
}

/* 카드 컴포넌트 스타일 */
.card {
  background-color: var(--bg-secondary);
  padding: var(--spacing-md);
  margin: var(--spacing-md) 0;
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--border-color);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

/* 입력 필드 스타일 */
input[type="text"],
input[type="email"],
textarea {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm);
  width: 100%;
  margin-bottom: var(--spacing-md);
  font-family: 'Fira Code', monospace;
  font-size: var(--font-size-sm);
}

input:focus,
textarea:focus {
  border-color: var(--accent-primary);
  outline: none;
  box-shadow: 0 0 0 2px var(--accent-secondary);
}

/* 섹션 제목 스타일 */
.section-title {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-md);
  color: var(--text-primary);
  border-bottom: 2px solid var(--border-color);
  padding-bottom: var(--spacing-sm);
}

/* 코드 블록 스타일 */
pre {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  overflow-x: auto;
  font-family: 'Fira Code', monospace;
  font-size: var(--font-size-sm);
}

code {
  color: var(--accent-primary);
}

/* 터미널 스타일 요소 */
.terminal {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  font-family: 'Fira Code', monospace;
  font-size: var(--font-size-sm);
  position: relative;
}

.terminal::before {
  content: '$ ';
  color: var(--success-color);
}

/* 스킬 바 스타일 */
.skill-bar {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  margin-bottom: var(--spacing-md);
  height: 25px;
}

.skill-bar-fill {
  background-color: var(--accent-primary);
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--spacing-sm);
  font-size: var(--font-size-xs);
  color: var(--bg-primary);
  transition: width var(--transition-slow);
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    text-align: center;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: var(--spacing-sm) 0;
  }

  .card {
    margin: var(--spacing-sm);
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: var(--font-size-xl);
  }

  button {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-xs);
  }

  pre,
  code,
  .terminal {
    font-size: var(--font-size-xs);
  }
}

/* 애니메이션 효과 */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn var(--transition-normal);
}

/* 프로젝트 그리드 레이아웃 */
.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacing-md);
}

/* 툴팁 스타일 */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  text-align: center;
  border-radius: var(--border-radius-md);
  padding: var(--spacing-xs) 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity var(--transition-normal);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
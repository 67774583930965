/* styles/Home.css */

.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-xl);
}

.project-carousel {
  margin-bottom: var(--spacing-xl);
}

.project-carousel img {
  max-height: 400px;
  object-fit: cover;
  border-radius: var(--border-radius-md);
}

.project-carousel .legend {
  background: rgba(0, 0, 0, 0.7);
  color: var(--text-primary);
  font-family: 'Fira Code', monospace;
  font-size: var(--font-size-sm);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
}

.section-title {
  color: var(--accent-primary);
  border-bottom: 2px solid var(--accent-secondary);
  padding-bottom: var(--spacing-xs);
  margin-top: var(--spacing-xl);
  font-size: var(--font-size-2xl);
}

.philosophy-text {
  font-size: var(--font-size-md);
  line-height: 1.6;
  color: var(--text-secondary);
}

.project-list ul,
.team ul {
  list-style-type: none;
  padding: 0;
}

.project-list li,
.team li {
  background-color: var(--bg-secondary);
  margin-bottom: var(--spacing-sm);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  transition: transform var(--transition-normal);
}

.project-list li:hover,
.team li:hover {
  transform: translateX(10px);
}

.contact-info {
  background-color: var(--bg-secondary);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-lg);
  margin-bottom: var(--spacing-lg);
}

.contact-button {
  display: block;
  width: 200px;
  margin: 0 auto;
  background-color: var(--accent-secondary);
  color: var(--text-primary);
  border: none;
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-md);
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: background-color var(--transition-normal), transform var(--transition-fast);
}

.contact-button:hover {
  background-color: var(--accent-primary);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .project-carousel img {
    max-height: 300px;
  }

  .contact-button {
    width: 100%;
  }

  .section-title {
    font-size: var(--font-size-xl);
  }
}

@media (max-width: 480px) {
  .home {
    padding: var(--spacing-md);
  }

  .project-carousel {
    margin-bottom: var(--spacing-lg);
  }

  .section-title {
    font-size: var(--font-size-lg);
  }

  .philosophy-text {
    font-size: var(--font-size-sm);
  }
}

.fade-in {
  animation: fadeIn var(--transition-normal);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.project-item {
  background-color: var(--bg-secondary);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.project-item h4 {
  color: var(--accent-primary);
  margin: 0 0 var(--spacing-sm);
  font-size: var(--font-size-lg);
}

.project-item p {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-sm);
}

.project-status {
  display: inline-block;
  background-color: var(--accent-secondary);
  color: var(--text-primary);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-sm);
}

.tech-stack {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-family: 'Fira Code', monospace;
}
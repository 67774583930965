/* About.css */

.about {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-xl);
}

.section-title {
  color: var(--accent-primary);
  border-bottom: 2px solid var(--accent-secondary);
  padding-bottom: var(--spacing-xs);
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-lg);
  font-size: var(--font-size-2xl);
}

.team-lead-card {
  display: flex;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  margin-bottom: var(--spacing-xl);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal);
}

.team-lead-card:hover {
  transform: translateY(-5px);
}

.team-lead-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.team-lead-info {
  padding: var(--spacing-lg);
  flex: 1;
}

.team-lead-info h2 {
  color: var(--accent-primary);
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-xs);
}

.team-lead-info h3 {
  color: var(--text-secondary);
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-md);
}

.team-members {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacing-lg);
}

.team-member-card {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal);
}

.team-member-card:hover {
  transform: translateY(-5px);
}

.team-member-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.team-member-card h3,
.team-member-card h4,
.team-member-card p {
  padding: 0 var(--spacing-md);
}

.team-member-card h3 {
  color: var(--accent-primary);
  font-size: var(--font-size-lg);
  margin-top: var(--spacing-md);
  margin-bottom: var(--spacing-xs);
}

.team-member-card h4 {
  color: var(--text-secondary);
  font-size: var(--font-size-md);
  margin-bottom: var(--spacing-md);
}

.team-member-card p {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-md);
}

.fade-in {
  animation: fadeIn var(--transition-normal);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .team-lead-card {
    flex-direction: column;
  }

  .team-lead-image {
    width: 100%;
    height: 300px;
  }

  .team-members {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .about {
    padding: var(--spacing-md);
  }

  .section-title {
    font-size: var(--font-size-xl);
  }

  .team-lead-info h2 {
    font-size: var(--font-size-lg);
  }

  .team-lead-info h3 {
    font-size: var(--font-size-md);
  }
}
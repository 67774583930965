.output {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-xl);
  box-sizing: border-box;
}

.section-title {
  font-size: clamp(var(--font-size-2xl), 5vw, var(--font-size-3xl));
  margin-bottom: var(--spacing-xl);
  text-align: center;
  color: var(--text-primary);
}

.learning-progress,
.ongoing-projects {
  margin-bottom: var(--spacing-2xl);
}

h2 {
  font-size: clamp(var(--font-size-xl), 4vw, var(--font-size-2xl));
  margin-bottom: var(--spacing-lg);
  color: var(--accent-primary);
}

.learning-topics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
}

.learning-topic-card {
  background: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal);
  border: 1px solid var(--border-color);
}

.learning-topic-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.learning-topic-card h3 {
  color: var(--text-primary);
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-sm);
}

.learning-topic-card h4 {
  color: var(--accent-primary);
  font-size: var(--font-size-md);
  margin-bottom: var(--spacing-md);
}

.learning-topic-card p {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  line-height: 1.6;
}

.project-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-xl);
  padding: var(--spacing-md);
}

.project-card {
  background: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  overflow: hidden;
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  border: 1px solid var(--border-color);
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.project-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform var(--transition-normal);
}

.project-card:hover .project-image img {
  transform: scale(1.05);
}

.project-content {
  padding: var(--spacing-lg);
}

.project-content h3 {
  color: var(--text-primary);
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-md);
}

.project-description {
  color: var(--text-secondary);
  font-size: var(--font-size-md);
  line-height: 1.6;
  margin-bottom: var(--spacing-lg);
}

.project-features {
  margin-bottom: var(--spacing-lg);
}

.project-features h4 {
  color: var(--text-primary);
  font-size: var(--font-size-md);
  margin-bottom: var(--spacing-sm);
}

.project-features ul {
  list-style: none;
  padding-left: 0;
}

.project-features li {
  color: var(--text-secondary);
  padding: var(--spacing-xs) 0;
  position: relative;
  padding-left: var(--spacing-lg);
}

.project-features li:before {
  content: "•";
  color: var(--accent-primary);
  position: absolute;
  left: 0;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
}

.tech-tag {
  background: var(--bg-primary);
  color: var(--accent-primary);
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: var(--border-radius-xl);
  font-size: var(--font-size-sm);
  transition: all var(--transition-normal);
  border: 1px solid var(--accent-primary);
}

.tech-tag:hover {
  background: var(--accent-primary);
  color: var(--bg-primary);
}

@media (max-width: 1200px) {
  .output {
    padding: var(--spacing-lg);
  }
}

@media (max-width: 768px) {
  .learning-topics,
  .project-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .output {
    padding: var(--spacing-md);
  }
}
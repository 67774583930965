.project-detail-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.project-header {
  text-align: center;
  margin-bottom: 3rem;
}

.project-header h1 {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.project-subtitle {
  font-size: 1.2rem;
  color: var(--text-secondary);
}

.project-overview,
.project-features,
.project-challenges,
.project-outcomes {
  margin-bottom: 3rem;
}

h2 {
  font-size: 2rem;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.tech-stack {
  margin-top: 2rem;
}

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.tech-tag {
  background-color: var(--background-secondary);
  color: var(--text-primary);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.feature-card {
  background-color: var(--background-secondary);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-card h3 {
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}

.feature-card p {
  color: var(--text-secondary);
  line-height: 1.6;
}

.challenges-list,
.outcomes-list {
  list-style-type: none;
  padding: 0;
}

.challenges-list li,
.outcomes-list li {
  background-color: var(--background-secondary);
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  color: var(--text-primary);
}

.error-message {
  text-align: center;
  color: var(--text-primary);
  font-size: 1.5rem;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .project-detail-page {
    padding: 1rem;
  }

  .project-header h1 {
    font-size: 2rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }
} 
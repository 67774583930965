/* Goal.css */

.goal {
  padding: var(--spacing-xl);
  max-width: 1200px;
  margin: 0 auto;
}

.our-goals {
  margin-bottom: var(--spacing-xl);
}

.goals-list {
  list-style: none;
  padding: 0;
}

.goal-item {
  background-color: var(--bg-secondary);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.goal-item:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

/* 프로젝트 섹션 스타일 */
.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.project-link {
  text-decoration: none;
  color: inherit;
}

.project-card {
  display: flex;
  flex-direction: column;
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.project-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-content {
  padding: var(--spacing-md);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.project-description {
  color: var(--text-secondary);
  margin: var(--spacing-sm) 0;
}

.project-features {
  margin-top: var(--spacing-md);
}

.project-features h4 {
  margin-bottom: var(--spacing-sm);
  color: var(--text-primary);
}

.project-features ul {
  list-style: none;
  padding-left: var(--spacing-md);
}

.project-features li {
  margin-bottom: var(--spacing-xs);
  position: relative;
}

.project-features li:before {
  content: "•";
  color: var(--accent-primary);
  position: absolute;
  left: -1em;
}

.tech-stack {
  margin-top: auto;
  padding-top: var(--spacing-md);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.tech-tag {
  background-color: var(--bg-primary);
  color: var(--accent-primary);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  border: 1px solid var(--accent-primary);
}

/* 학습 중인 기술 섹션 스타일 */
.learning-progress {
  margin-bottom: var(--spacing-xl);
}

.learning-topics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.learning-topic-card {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.learning-topic-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.learning-topic-card h3 {
  color: var(--accent-primary);
  margin-bottom: var(--spacing-xs);
}

.learning-topic-card h4 {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-sm);
}

.learning-topic-card p {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  line-height: 1.6;
}

@media (max-width: 768px) {
  .goal {
    padding: var(--spacing-md);
  }

  .project-grid {
    grid-template-columns: 1fr;
  }
}
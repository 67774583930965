.header {
  background-color: var(--bg-secondary);
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  animation: fadeIn 0.5s ease-out;
}

.header-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-nav-container {
  margin-top: 0;
}

.logo {
  text-decoration: none;
  color: var(--accent-primary);
  font-weight: bold;
  font-size: 1.5rem;
  font-family: 'Fira Code', monospace;
}

.header-nav-list {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.header-nav-link {
  color: var(--text-primary);
  text-decoration: none;
  font-family: 'Fira Code', monospace;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.header-nav-link:hover {
  color: var(--accent-primary);
}

.menu-button {
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  padding: 0;
  display: none;
}

@media (max-width: 768px) {
  .header-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-nav-container {
    margin-top: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--bg-secondary);
  }

  .menu-button {
    display: flex;
    align-items: center;
  }

  .header-nav-list {
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: none;
    padding: 1rem 0;
  }

  .header-nav-list.open {
    display: flex;
  }

  .header-nav-list li {
    margin: 0.5rem 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}